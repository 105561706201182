<template>

    <b-container id="rootCourse" fluid>
        <b-row>
            <b-col md="2" offset-md="1">
                <b-img :src="require('@/assets/images/logo_darkblue_bg.png')" width="200%"></b-img>
            </b-col>
            <!-- Logout buttom -->
            <b-col md="8" class="d-flex justify-content-end">
                <ButtomLogout />
            </b-col>
            <!-- End of Logout buttom -->
        </b-row>
        <b-row class="p-5">
            <b-col md="4" offset-md="4" class="text-center">
                <b-img :src="require('@/assets/images/logo_white_bg.png')" width="300%"></b-img>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="4" offset-md="4" class="text-center">
                <h4 class="text-light">Seleccione el curso :</h4>
            </b-col>
        </b-row>
        <b-row class="p-5">
            <b-col md="3" offset-md="3">
                <b-button href="/students" variant="secondary" size="lg"
                    class="border border-light w-100 h-100">ENGLISH</b-button>
            </b-col>
            <b-col md="3">
                <b-button variant="secondary" size="lg" class="border border-light w-100 h-100">ESPAÑOL<br />(Comming
                    Soon)</b-button>
            </b-col>
        </b-row>
    </b-container>

    <default-footer bg-color="#092040" />

</template>

<style>
#rootCourse {
    padding-top: 10%;
    padding-bottom: 10%;
    background-color: #092040;
    min-height: 50%;
    min-height: 50vh;
}
</style>

<script>
import ButtomLogout from "./ButtomLogout.vue";
import DefaultFooter from "./App/DefaultFooter.vue";

export default {
  name: 'CoursePage',
  components: {
    ButtomLogout,
    DefaultFooter,
  }
}
</script>