<template>

    <b-container id="rootAdults" fluid>
        
        <DefaultTopNavbar />

        <b-row>
            <b-col md="5" offset="1">
                <span id="phrase-after-logo">
                    Keep improving your English skills with activities specially selected for you!
                </span>
            </b-col>
        </b-row>
        <b-row><b-col md="12">&nbsp;</b-col></b-row>
        <b-row>
            <b-col md="8" offset="2" style="z-index: 10;">
                <b-img :src="require('@/assets/images/5376badc857b114ee99f1278e1c57df2.jpg')" fluid-grow></b-img>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="1" offset="9">
                <b-img :src="require('@/assets/images/b29aa2460379d659b39b036b9e2aa897.svg')" width="100%"></b-img>
            </b-col>
        </b-row>
        <b-row id="div-skills" align-v="center">
            <b-col md="12">
                <b-container fluid>
                    <b-row class="py-5">
                        <b-col md="5" offset="1">
                            <span id="skills-title">
                                SKILLS
                            </span>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="3" offset="4">
                            <b-img :src="require('@/assets/images/46d56c840ed60fb17a67a83634b93b00.jpg')"
                                fluid-grow></b-img>
                        </b-col>
                        <b-col md="3" offset="1">
                            <b-img :src="require('@/assets/images/818d3502705fa51ee5c2e8d85cdc31ae.jpg')"
                                fluid-grow></b-img>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="img-title-item" md="3" offset="4">
                            Listening
                        </b-col>
                        <b-col class="img-title-item" md="3" offset="1">
                            Reading
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="img-text" md="3" offset="4">
                            Listening will help you improve your understanding of the language and your pronunciation.
                        </b-col>
                        <b-col class="img-text" md="3" offset="1">
                            Reading will help you to improve your understanding of the language and build your
                            vocabulary.
                        </b-col>
                    </b-row>
                </b-container>
            </b-col>
        </b-row>
        <b-row id="div-mas-informaciones" align-h="center" align-v="center">
            <b-col md="12">
                <b-container fluid>
                    <b-row>
                        <b-col md="10" offset="1" class="text-center">
                            <span id="remark-text">
                                Todos los alumnos de Alianza pueden realizar el Test de Nivel de forma gratuita. Para
                                obtener el certificado de nivel, la realización del test es obligatoria.
                            </span>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="10" offset="1" class="pt-5 text-center">
                            <b-button id="mas-informaciones" href="/ltcertificate" size="xl"
                                class="px-5 py-3 pt-4 border border-light">
                                <h3>
                                    MÁS INFORMACIONES AQUÍ
                                </h3>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </b-col>
        </b-row>
    </b-container>

    <default-footer bg-color="#FFFFFF" />

</template>

<style>
#rootAdults {
    padding-top: 10px;
    background-color: #FFFFFF;
}
#phrase-after-logo {
    font-size: xx-large;
}
#div-skills {
    background-color: gainsboro;
    min-height: 768px;
}
#skills-title {
    font-size: xx-large;
    font-weight: bolder;
}
.img-title-item {
    font-size: xx-large;
}
.img-text {
    font-size: large;
    text-align: justify;
}
#div-mas-informaciones {
    min-height: 768px;
}
#mas-informaciones {
    background-color: #d2367a;
}
#remark-text {
    font-size: xx-large;
}
</style>

<script>
import DefaultFooter from "./App/DefaultFooter.vue";
import DefaultTopNavbar from "./App/DefaultTopNavbar.vue";

export default {
  name: 'StudentsPage',
  components: {
    DefaultFooter,
    DefaultTopNavbar,
  },
}
</script>