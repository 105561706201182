<template>

    <!-- Rodapé -->
    <b-container id="footer" class="p-0 m-0 pt-5" fluid>
        <b-row>
            <b-col md="12" style="z-index: 100;">
                <b-img class="img-fluid w-100" :src="require('@/assets/images/footer.png')"></b-img>
            </b-col>
        </b-row>
    </b-container>
    <!-- End of Rodapé -->

</template>

<style scoped>
#footer {
    background-color: v-bind('bgColor');
}
</style>

<script>

export default {
  name: 'DefaultFooter',
  props: {
    bgColor: {
        type: String,
        required: false
    }
  }
}
</script>