<template>

  <!-- Main container with the view for router -->
  <b-container class="p-0 m-0" fluid>
    <router-view></router-view>
  </b-container>
  <!-- End of main container with the view for router -->

</template>

<script>
import store from '@/store';

export default {
  name: 'App',
  beforeCreate() {
    if ( ! store.state.isLogged) {
      this.$router.push('/')
        .catch(() => {
          console.log('Not logged, push login !')
        });
    }
  },
  async created() {
    // Global title
    document.title = "Alianza America";

    // Set time on lost focus
    window.onblur = () => {
      this.lostFocus = this.$moment(new Date());
    }

    // Refresh if time since @lostFocus is grant then @this.$settings.config.windowRefreshTimeout
    window.onfocus = () => {
      let minutes = this.$moment(new Date()).diff(this.lostFocus, "minutes");
      if (minutes > this.$settings.config.windowRefreshTimeout) {
        window.location.reload();
      }
    }
  },
}
</script>