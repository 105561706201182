<template>

  <!-- Site -->
  <b-container class="p-0 m-0" fluid>

    <a id="page-1" aria-hidden="true" style="visibility:hidden;"></a>

    <!-- Primeira seção -->
    <section id="XAduHUdosr7CVPVh" style="position:relative; overflow:hidden; align-items:center; grid-template-columns:auto 100rem auto; z-index:0;">

    <b-container class="p-0 m-0" fluid>

      <!-- Div externa com o video em background -->
      <div id="tSnKzLqxZMucBrVB" style="grid-area:1 / 1 / 2 / 4; display:grid; position:absolute; min-height:100%; min-width:100%; z-index: 0;">
        <div id="dRml73SxEfM7ytNO" style="z-index:0;">
            <div id="r3e4gAOVWCSI8wU6" style="box-sizing:border-box; width:100%; height:100%; transform:rotate(0deg);">
                <div id="emQoJluEPHaSwafC" style="width:100%; height:100%; opacity:1.0;">
                    <div id="UEjZ7moOgsdtJPvg" class="video_container" style="background-color:#ffffff; opacity:1.0; transform:scale(1, 1); width:100%; height:100%; overflow:hidden; position:relative;">
                        <div id="RnuUmoJ0fRiespuU" style="width:calc(100% * max(1, var(--scale-fill, 1))); height:calc(100.04882812% / min(1, var(--scale-fill, 1))); position:absolute; top:50%; left:50%; opacity:1.0;">

        <video
          src="@/assets/videos/background.mp4"
          playsinline=""
          preload="none"
          data-volume="0.0"
          autoplay=""
          muted=""
          loop=""
          style="width:100%; height:100%; display:block; object-fit:cover; object-position: 50% 50%; transform: translate(-50%, -50%) rotate(0deg);"
        ></video>

                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <b-row><b-col md="12">&nbsp;</b-col></b-row>
      <b-row>
        <b-col md="1" offset-md="1" style="z-index: 1;">
          <b-img :src="require('@/assets/images/logo_circle_transparent.png')" width="160%"></b-img>
        </b-col>
        <b-col md="2" offset-md="8" style="z-index: 2;">
          <a id="e8YyL2O4jH3IKhLf" @click="showLoginWindow" target="_self" rel="noopener" href="#" style="color:#002740; font-weight:700; pointer-events:all;">
            Soy Alumno
          </a>
        </b-col>
      </b-row>

    </b-container>

    </section>
    <!-- End of primeira seção -->

    <a id="page-2" aria-hidden="true" style="visibility:hidden;"></a>

    <!-- Segunda seção -->
    <section id="MhHHtfzC9NOfcyLj" style="position:relative; overflow:hidden; align-items:center; z-index:0;">

    <b-container class="p-0 m-0 pt-5" fluid>
      <b-row><b-col md="12">&nbsp;</b-col></b-row>
      <b-row align-v="center">
        <b-col md="6" style="z-index: 10;">
          <b-img :src="require('@/assets/images/woman_typing.jpg')" width="800%"
          style="border-top-right-radius: 60px; border-bottom-right-radius: 60px;"></b-img>
        </b-col>
        <b-col md="6" style="z-index: 10;">
          <b-container class="p-0 m-0 h-100 border center" fluid>
            <b-row>
              <b-col md="6">
                <h1>Clases<br/>Particulares</h1>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <span>Clases de inglés particulares y personalizadas todos los días, Programa una clase de prueba 100% gratuita.</span>
              </b-col>
            </b-row>
          </b-container>
        </b-col>
      </b-row>
    </b-container>

    </section>
    <!-- End of segunda seção -->

    <a id="page-3" aria-hidden="true" style="visibility:hidden;"></a>
      
    <!-- Terceira seção -->
    <section id="nt2ciQLlCJoNBxQj" style="position:relative; overflow:hidden; align-items:center; z-index:0; margin-top:-1px;">

    <b-container class="p-0 m-0" fluid>
      <b-row><b-col class="text-center" md="6" offset-md="3"><h1><i>LA MANERA CORRECTA DE APRENDER</i></h1></b-col></b-row>
      <b-row><b-col md="12">&nbsp;</b-col></b-row>
      <b-row>
        <b-col class="text-center" md="6" offset-md="3">
        <h4>
          ¡Aquí aprendes inglés de básico a avanzado de la manera correcta y 100% en línea! Conoce cómo funcionan nuestros 4 pilares para resultados rápidos:
        </h4>
        </b-col>
      </b-row>
      <b-row><b-col md="12">&nbsp;</b-col></b-row>
      <b-row align-v="center">
        <b-col
          md="3"
          xl="2" offset-xl="2"
          style="z-index: 21;">
          <b-img :src="require('@/assets/images/practica.png')" width="300%"></b-img>
        </b-col>
        <b-col
          md="3"
          xl="2"
          style="z-index: 22;">
          <b-img :src="require('@/assets/images/flexibilidad.png')" width="300%"></b-img>
        </b-col>
        <b-col
          md="3"
          xl="2"
          style="z-index: 23;">
          <b-img :src="require('@/assets/images/personalization.png')" width="300%"></b-img>
        </b-col>
        <b-col
          md="3"
          xl="2"
          style="z-index: 24;">
          <b-img :src="require('@/assets/images/liberdad.png')" width="300%"></b-img>
        </b-col>
      </b-row>
    </b-container>

    </section>
    <!-- End of terceira seção -->

    <a id="page-4" aria-hidden="true" style="visibility:hidden;"></a>

  </b-container>
  <!-- End of site -->

  <b-container class="p-0 m-0" fluid>
    <default-footer/>
  </b-container>

  <!-- Modal window -->
  <b-modal id="modal-form-1" v-model="modalShow" centered hide-footer hide-header>

    <b-row>
    <b-col md="12" offset-md="12">

        <form v-on:submit.prevent="login">

          <b-card class="border-alianza" fluid>

            <b-card-header class="p-0 m-0 bg-white">
              <b-img :src="require('@/assets/images/logo_white_mini.png')" width="150%" fluid></b-img>
            </b-card-header>

            <b-card-body>

              <b-row>
                <b-col md="12">
                  <b-container fluid>

                    <b-input-group class="py-3 mb-2">
                      <b-input-group-prepend is-text>
                        <font-awesome-icon icon="user"></font-awesome-icon>
                      </b-input-group-prepend>
                      <b-form-input
                        id="input-1"
                        v-model="form.login"
                        type="text"
                        placeholder="Usuário"></b-form-input>
                    </b-input-group>

                  </b-container>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="12">
                  <b-container fluid>

                    <b-input-group class="py-3 mb-2">
                      <b-input-group-prepend is-text>
                        <font-awesome-icon icon="key"></font-awesome-icon>
                      </b-input-group-prepend>
                      <b-form-input
                        id="input-2"
                        v-model="form.password"
                        type="password"
                        placeholder="Senha de Autenticação"></b-form-input>
                    </b-input-group>

                  </b-container>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="12" v-show="infoError">
                  <b-container fluid>
                    <strong><font color="red">{{ message }}</font></strong>
                  </b-container>
                </b-col>
              </b-row>

            </b-card-body>

            <b-card-footer>
              <b-row>
                <b-col md="12" class="text-center py-2">
                  <b-button type="submit" class="alianza">Login</b-button>
                </b-col>
              </b-row>
            </b-card-footer>

          </b-card>

        </form>

      </b-col>
    </b-row>

  </b-modal>
  <!-- End of modal window -->

</template>

<script>
import store from '@/store';
import ls from 'local-storage';

import DefaultFooter from './App/DefaultFooter.vue';

export default {
  name: 'HomePage',
  components: {
    DefaultFooter
  },
  data() {
    return {
      modalShow: false,
      infoError: false,
      message: '',
      form: {
        login: '',
        encrypted: '',
        password: ''
      }
    }
  },
  methods: {
    showLoginWindow() {
      this.modalShow = true;
    },
    async login() {
      /* Hide message, if visible */
      if (this.infoError) {
        this.infoError = false;
      }

      /* Authenticate using backend api */
      let response = await this.$http.post('api/login', this.form);

      if (response.data.success && response.data.token) {
        this.infoError = true;
        this.message = 'Autenticado';

        /**
         *  Armazena o token em local-storage.
         *  O ls atua como "setter" quando o segundo valor é informado.
         */
        ls('token', response.data.token);

        /* Chama a mutation em store (através do commit ?) */
        store.commit('loginUser');

        /* Desvia para raiz */
        this.$router.push('/course');

        /* Fecha a janela de login */
        this.modalShow = false;
      }
      else if (!response.data.success) {
        /* Em caso de erro, mostra a mensagem retornada */
        this.infoError = true;
        this.message = response.data.message;
      }
    }
  },
  computed: {
    isLogged() {
      return store.state.isLogged;
    }
  }
}
</script>

<style>
  /* Vue main div */
  #app {
    background-color: rgb(241, 241, 241);
  }

  #main-box {
  }

  .border-alianza {
    border-color: rgb(210, 54, 122);
  }

  .alianza {
    background-color: rgb(210, 54, 122);
  }

  @media (max-width: 375px) {
    #XAduHUdosr7CVPVh  {
      min-height: calc(calc(19.45199374 * var(--1vh, 1vh)) - 9.72599687px);
    }
    #MhHHtfzC9NOfcyLj  {
      min-height: calc(calc(18.19209228 * var(--1vh, 1vh)) - 9.09604614px);
    }
    #nt2ciQLlCJoNBxQj  {
      min-height: calc(calc(46.14282113 * var(--1vh, 1vh)) - 23.07141057px);
    }
    #nDFFPsmBtCIObdNP  {
      min-height: calc(calc(10.9820311 * var(--1vh, 1vh)) - 5.49101555px);
    }
  }

  @media (min-width: 375.05px) and (max-width: 480px) {
    #XAduHUdosr7CVPVh  {
      min-height: calc(calc(19.17987364 * var(--1vh, 1vh)) - 9.58993682px);
    }
    #MhHHtfzC9NOfcyLj  {
      min-height: calc(calc(19.42334901 * var(--1vh, 1vh)) - 9.7116745px);
    }
    #nt2ciQLlCJoNBxQj  {
      min-height: calc(calc(46.38658596 * var(--1vh, 1vh)) - 23.19329298px);
    }
    #nDFFPsmBtCIObdNP  {
      min-height: calc(calc(8.32429243 * var(--1vh, 1vh)) - 4.16214622px);
    }
  }

  @media (min-width: 480.05px) and (max-width: 768px) {
    #XAduHUdosr7CVPVh  {
      min-height: calc(calc(21.96337362 * var(--1vh, 1vh)) - 10.98168681px);
    }
    #MhHHtfzC9NOfcyLj  {
      min-height: calc(calc(27.37230935 * var(--1vh, 1vh)) - 13.68615467px);
    }
    #nt2ciQLlCJoNBxQj  {
      min-height: calc(calc(30.26613124 * var(--1vh, 1vh)) - 15.13306562px);
    }
    #nDFFPsmBtCIObdNP  {
      min-height: calc(calc(8.54375234 * var(--1vh, 1vh)) - 4.27187617px);
    }
  }

  @media (min-width: 768.05px) and (max-width: 1024px)  {
    #XAduHUdosr7CVPVh  {
      min-height: calc(calc(21.10245024 * var(--1vh, 1vh)) - 10.55122512px);
    }
    #MhHHtfzC9NOfcyLj  {
      min-height: calc(calc(26.29936575 * var(--1vh, 1vh)) - 13.14968288px);
    }
    #nt2ciQLlCJoNBxQj  {
      min-height: calc(calc(29.2029819 * var(--1vh, 1vh)) - 14.60149095px);
    }
    #nDFFPsmBtCIObdNP  {
      min-height: calc(calc(8.20885314 * var(--1vh, 1vh)) - 4.10442657px);
    }
  }

  @media (min-width: 1024.05px)  {
    #XAduHUdosr7CVPVh  {
      /*min-height: calc(calc(22.64150943 * var(--1vh, 1vh)) - 11.32075472px);*/
      min-height: 97vh;
    }
    #MhHHtfzC9NOfcyLj  {
      /*min-height: calc(calc(22.64150943 * var(--1vh, 1vh)) - 11.32075472px);*/
      min-height: 70vh;
    }
    #nt2ciQLlCJoNBxQj  {
      /*min-height: calc(calc(22.64150943 * var(--1vh, 1vh)) - 11.32075472px);*/
      min-height: 10vh;
    }
    #nDFFPsmBtCIObdNP  {
      min-height: calc(calc(9.43396226 * var(--1vh, 1vh)) - 4.71698113px);
    }
  }
</style>