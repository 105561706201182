<template>

    <b-container id="rootLTC" fluid>
        <b-row>
            <b-col md="3">
                <b-img :src="require('@/assets/images/logo_white_mini.png')" width="200%"></b-img>
            </b-col>
            <b-col md="1" offset-md="4" class="d-flex justify-content-end">
                <b-link href="#">Listening</b-link>
            </b-col>
            <b-col md="1" class="d-flex justify-content-end">
                <b-link href="#">Reading</b-link>
            </b-col>
            <b-col md="2" class="d-flex justify-content-end">
                <b-link to="#" class="link-primary">Level Test/Certificate</b-link>
            </b-col>
            <!-- Logout buttom -->
            <b-col md="1" class="d-flex justify-content-end">
                <ButtomLogout fg-color="#d2367a" />
            </b-col>
            <!-- End of Logout buttom -->
        </b-row>
        <b-row id="info-text" align-v="center">
            <b-col md="10" offset="1">
                <b-container fluid>
                    <b-row>
                        <b-col md="5">
                            <span id="ltc-title">
                                <h1>
                                    LEVEL TEST & CERTIFICATE
                                </h1>
                            </span>
                        </b-col>
                    </b-row>
                    <b-row class="py-5">
                        <b-col md="12" id="ltc-text">
                            El test de nivel se realiza gratuitamente a los alumnos de la escuela los viernes de cada
                            semana. El test de nivel debe ser solicitado al profesor, y para su realización es necesario
                            hacer previamente un simulacro en clase, según el nivel y curso del alumno. Después de que
                            el profesor envíe los resultados y la información del alumno a la escuela, tú recibirás un
                            mensaje por WhatsApp con informaciones importantes sobre el test, así como el enlace para
                            obtener la cita.
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="5">
                            <b-button href="/adults">
                                <font-awesome-icon size="xl" icon="caret-left" class="float"></font-awesome-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </b-col>
        </b-row>

    </b-container>

    <default-footer bg-color="#FFFFFF" />

</template>

<style>
#rootLTC {
    padding-top: 10px;
    background-color: #FFFFFF;
}
#info-text {
    min-height: 768px;
}
#ltc-title {
    font-size: xx-large;
}
#ltc-text {
    font-size: x-large;
    text-align: justify;
}
</style>

<script>
import ButtomLogout from "./ButtomLogout.vue";
import DefaultFooter from "./App/DefaultFooter.vue";

export default {
  name: 'LevelTestCertificate',
  components: {
    ButtomLogout,
    DefaultFooter,
  },
}
</script>