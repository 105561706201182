<template>

    <!-- Buttom Logout -->
    <font-awesome-icon
        id="logout-icon"
        icon="right-from-bracket"
        class="float"
        @click="doLogout"></font-awesome-icon>
    <!-- End of Buttom Logout -->

</template>

<style>
#logout-icon {
    color: v-bind('fgColor');
}
</style>

<script>
export default {
    name: "ButtomLogout",
    methods: {
        doLogout() {
            this.$router.push('/logout');
        }
    },
    props: {
        fgColor: {
            type: String,
            required: false,
            default: '#FFFFFF'
        }
    }
}
</script>