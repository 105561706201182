<template>
    <div>
        Logout...
    </div>
</template>

<script>
import store from '@/store'

export default {
    name: 'LogOut',
    mounted() {
        localStorage.removeItem('token');
        store.commit('LOGOUT_USER');
        this.$router.push('/');
    }
}
</script>