<template>
    <b-container id="rootDefaultTopNavbar" fluid>
        <b-row>
            <b-col md="5" offset="1">
                <b-img :src="require('@/assets/images/logo_web_white_bg.png')" width="300%"></b-img>
            </b-col>
            <b-col md="6">
                <b-row>
                    <b-col md="3" class="d-flex justify-content-end">
                        <b-link class="link-opacity-50 link-opacity-100-hover link-underline link-underline-opacity-0" to="/listening">Listening</b-link>
                    </b-col>
                    <b-col md="3" class="d-flex justify-content-center">
                        <b-link class="link-opacity-50 link-opacity-100-hover link-underline link-underline-opacity-0" to="/reading">Reading</b-link>
                    </b-col>
                    <b-col md="3" class="d-flex justify-content-right">
                        <b-link class="link-opacity-50 link-opacity-100-hover link-underline link-underline-opacity-0" to="/ltcertificate">Level Test/Certificate</b-link>
                    </b-col>

                    <!-- Logout buttom -->
                    <b-col md="3" class="d-flex justify-content-end">
                        <ButtomLogout fg-color="#d2367a" />
                    </b-col>
                    <!-- End of Logout buttom -->

                </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>

<style>
#rootDefaultTopNavbar {
    padding-top: 10px;
    padding-bottom: 20px;
    background-color: #FFFFFF;
}
#navbar-link:any-link {
    text-decoration: none;
}
</style>

<script>
import ButtomLogout from "../ButtomLogout.vue";

export default {
  name: 'DefaultTopNavbar',
  components: {
    ButtomLogout,
  },
}
</script>