<template>

    <b-container id="rootReading" fluid>

        <DefaultTopNavbar />

        <b-row>
            <b-col md="1" offset-md="1" class="d-flex justify-content-begin">
                <h1>Reading</h1>
            </b-col>
        </b-row>
        <b-row><b-col md="12">&nbsp;</b-col></b-row>
        <b-row>
            <b-col md="10" offset="2" class="d-flex justify-content-end">
                <b-row>
                    <b-col md="4" offset="1" style="z-index: 10;">
                        <b-row>
                            <b-col md="12">
                                <b-img :src="require('@/assets/images/796f0bacddc5b53bc05f4d10f0236c29.jpg')" fluid-grow></b-img>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="12">
                                <h1>Literary Books</h1>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col md="1">&nbsp;</b-col>
                    <b-col md="4">
                        <b-row>
                            <b-col md="12">
                                <b-img :src="require('@/assets/images/fafcea850f67b6e3a83ff2be71cdb33c.jpg')" fluid-grow></b-img>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="12">
                                <h1>Magazines & Newspapers</h1>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="5" offset="1" class="pt-5">
                <b-button href="/adults">
                    <font-awesome-icon size="xl" icon="caret-left" class="float"></font-awesome-icon>
                </b-button>
            </b-col>
        </b-row>
    </b-container>

    <default-footer bg-color="#FFFFFF" />

</template>

<style>
#rootReading {
    padding-top: 10px;
    background-color: #FFFFFF;
}
</style>

<script>
import DefaultFooter from "./App/DefaultFooter.vue";
import DefaultTopNavbar from "./App/DefaultTopNavbar.vue";

export default {
  name: 'AdultsReadingPage',
  components: {
    DefaultFooter,
    DefaultTopNavbar,
  },
}
</script>
