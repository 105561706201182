const settings = {
    restapi: 'https://api.alianzamerica.net/',
    magicKey: 'Alianza America - Escola de Línguas',
    locale: 'pt-br',
    config: {
        perPage: 13,
        perPageCompact: 16,
        maxImageSize: 1000000,
        toastErrorTimeout: 8000,
        toastSuccessTimeout: 5000,
        windowRefreshTimeout: 20    // in minutes
    }
}

export default settings;