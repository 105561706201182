<template>

    <b-container id="rootStudents" fluid>
        <b-row>
            <b-col md="2" offset-md="1">
                <b-img :src="require('@/assets/images/logo_darkblue_bg.png')" width="200%"></b-img>
            </b-col>
            <!-- Logout buttom -->
            <b-col md="8" class="d-flex justify-content-end">
                <ButtomLogout />
            </b-col>
            <!-- End of Logout buttom -->
        </b-row>
        <b-row>
            <b-col md="10" offset-md="1" class="pt-5 text-center">
                <h1 class="text-light">Let's keep on learning!</h1>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="10" offset-md="1" class="pt-2 text-center">
                <h4 class="text-light">To begin, choose your age group:</h4>
            </b-col>
        </b-row>
        <b-row class="pt-5">
            <b-col md="10" offset-md="1">
                <b-row class="text-center">
                    <b-col md="4">
                        <video src="@/assets/videos/kids.mp4" playsinline="" preload="none" data-volume="0.0"
                            autoplay="" muted="" loop="" class="w-100 h-100"></video>
                    </b-col>
                    <b-col md="4" class="text-center">
                        <video src="@/assets/videos/preteen.mp4" playsinline="" preload="none" data-volume="0.0"
                            autoplay="" muted="" loop="" class="w-100 h-100"></video>
                    </b-col>
                    <b-col md="4" class="text-center">
                        <video src="@/assets/videos/teen.mp4" playsinline="" preload="none" data-volume="0.0"
                            autoplay="" muted="" loop="" class="w-100 h-100"></video>
                    </b-col>
                </b-row>
                <b-row class="text-light">
                    <b-col md="4" class="border-bottom border-bottom-light">
                        <h4><b-link class="link-light link-underline link-underline-opacity-0" href="#">KIDS</b-link></h4>
                    </b-col>
                    <b-col md="4" class="border-bottom border-bottom-light">
                        <h4><b-link class="link-light link-underline link-underline-opacity-0" href="#">PRE-TEENS</b-link></h4>
                    </b-col>
                    <b-col md="4" class="border-bottom border-bottom-light">
                        <h4><b-link class="link-light link-underline link-underline-opacity-0" href="/adults">TEENS & ADULTS</b-link></h4>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-container>

    <default-footer bg-color="#092040" />

</template>

<style>
#rootStudents {
    padding-top: 5%;
    padding-bottom: 5%;
    background-color: #092040;
    min-height: 50%;
    min-height: 50vh;
}
</style>

<script>
import ButtomLogout from "./ButtomLogout.vue";
import DefaultFooter from "./App/DefaultFooter.vue";

export default {
  name: 'StudentsPage',
  components: {
    ButtomLogout,
    DefaultFooter,
  }
}
</script>