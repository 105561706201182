import { createWebHistory, createRouter } from 'vue-router';

import LogOut from "../components/LogOut.vue";
import HomePage from "../components/HomePage.vue";
import CoursePage from "../components/CoursePage.vue";
import StudentsPage from "../components/StudentsPage.vue";
import AdultsPage from "../components/AdultsPage.vue";
import AdultsReadingPage from '@/components/AdultsReadingPage.vue';
import AdultsListeningPage from '@/components/AdultsListeningPage.vue';
import LevelTestCertificate from "../components/LevelTestCertificate.vue";

const routes = [
    {
        name: 'Root',
        path: '/',
        component: HomePage
    },
    {
        name: 'HomePage',
        path: '/home',
        component: HomePage
    },
    {
        name: 'CoursePage',
        path: '/course',
        component: CoursePage
    },
    {
        name: 'StudentsPage',
        path: '/students',
        component: StudentsPage
    },
    {
        name: 'AdultsPage',
        path: '/adults',
        component: AdultsPage
    },
    {
        name: 'AdultsListeningPage',
        path: '/listening',
        component: AdultsListeningPage
    },
    {
        name: 'AdultsReadingPage',
        path: '/reading',
        component: AdultsReadingPage
    },
    {
        name: 'LevelTestCertificate',
        path: '/ltcertificate',
        component: LevelTestCertificate
    },
    {
        name: 'LogOut',
        path: '/logout',
        component: LogOut
    }
];

export default createRouter({
    mode: 'history',
    history: createWebHistory(),
    routes
});